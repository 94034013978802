//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { format } from 'date-fns';

import gtmMixin from '~/mixins/gtm';

export default {
  mixins: [gtmMixin],
  props: {
    page: {
      type: Object,
    },
  },
  computed: {
    articleDate() {
      return format(new Date(this.page.post_date), 'MMM d, yyyy');
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.forceOptimizeActive();
    });
  },
};
