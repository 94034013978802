import { render, staticRenderFns } from "./SingleBlog.vue?vue&type=template&id=06660bb3&scoped=true&"
import script from "./SingleBlog.vue?vue&type=script&lang=js&"
export * from "./SingleBlog.vue?vue&type=script&lang=js&"
import style0 from "./SingleBlog.vue?vue&type=style&index=0&id=06660bb3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06660bb3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BlogCategoryDropdown: require('/home/node/frontend/components/blogs/BlogCategoryDropdown.vue').default,SingleBlogContent: require('/home/node/frontend/components/blogs/SingleBlogContent.vue').default,BlogSidebar: require('/home/node/frontend/components/blogs/BlogSidebar.vue').default,Testimonials: require('/home/node/frontend/components/includes/Testimonials.vue').default,BuildersBulletin: require('/home/node/frontend/components/includes/BuildersBulletin.vue').default})
