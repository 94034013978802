//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InstagramSvg from '~/static/svg/instagram.svg';
import FacebookSvg from '~/static/svg/facebook.svg';
import YoutubeSvg from '~/static/svg/youtube.svg';
import AddIconSvg from '~/static/svg/install-icons_add.svg';
import { mapGetters } from 'vuex';

export default {
  components: {
    InstagramSvg,
    FacebookSvg,
    YoutubeSvg,
    AddIconSvg,
  },
  props: {
    page: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      blogCategories: 'blog/getBlogCategories',
    }),
    relatedPosts() {
      if (this.page.type === 'single-blog') {
        return this.page.related_posts;
      }
      return null;
    },
    processedCategories() {
      const categories = Object.values(this.blogCategories).sort((a, b) =>
        a.title.localeCompare(b.title)
      );

      const result = categories
        .filter(category => category.parent === 0)
        .map(category => ({ ...category, children: [] }));

      result.forEach(category => {
        if (!category.children) {
          category.children = [];
        }
        category.children = categories.filter(
          otherCategory => otherCategory.parent === category.id
        );
      });

      return result;
    },
  },
  data() {
    return {
      showChildren: [],
      getAllLinkText: this.$t('general.all') ?? 'All',
    };
  },
  methods: {
    toggleCategory(category) {
      if (this.showChildren.includes(category.id)) {
        this.showChildren = this.showChildren.filter(id => id !== category.id);
      } else {
        this.showChildren.push(category.id);
      }
    },
    getCategoryTier(cat) {
      if (!cat || typeof cat.parent !== 'number' || cat.parent === 0) return 0;

      let tier = 0;
      let parent = cat;
      const categories = this.blogCategories;
      if (categories && typeof cat.parent === 'number') {
        let abort = Object.keys(categories).length;
        let count = 0;
        while (parent.parent !== 0 && count <= abort) {
          tier++;

          parent = categories[`${parent.parent}`] ?? 0;
          count++;
        }
      }

      return tier;
    },
    categoryClasses(cat) {
      const tier = this.getCategoryTier(cat);
      if (!cat || typeof cat.parent !== 'number') return ``;
      let classes = `category-${cat.slug} tier-${tier}`;

      if (tier > 0) {
        classes += ` child-category`;
      }

      return classes;
    },
    isActiveCategory(id) {
      return this.page.data?.active_cat && id === this.page.data.active_cat;
    },
  },
  mounted() {
    // on startup "select" all parent categories with children
    this.showChildren = this.processedCategories
      .filter(cat => cat.children.length > 0)
      .map(cat => cat.id);
  },
};
