//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import gsap from 'gsap';
import ArrowRight from '~/static/svg/arrow-right.svg';
import sharedVariables from '~/util/shared-variables.js';

export default {
  components: {
    ArrowRight,
  },
  data() {
    return {
      expand: false,
      showChildren: [],
      getAllLinkText: this.$t('general.all') ?? 'All',
    };
  },
  computed: {
    ...mapGetters({
      blogCategories: 'blog/getBlogCategories',
    }),
    label: {
      get() {
        return this.$t('blog.categories');
      },
      set(val) {
        return val;
      },
    },
    processedCategories() {
      const categories = Object.values(this.blogCategories).sort((a, b) =>
        a.title.localeCompare(b.title)
      );

      const result = categories
        .filter(category => category.parent === 0)
        .map(category => ({ ...category, children: [] }));

      result.forEach(category => {
        if (!category.children) {
          category.children = [];
        }
        category.children = categories.filter(
          otherCategory => otherCategory.parent === category.id
        );
      });

      return result;
    },
  },
  methods: {
    toggleCategory(category) {
      if (this.showChildren.includes(category.id)) {
        this.showChildren = this.showChildren.filter(id => id !== category.id);
      } else {
        this.showChildren.push(category.id);
      }
    },
    getCategoryTier(cat) {
      if (!cat || typeof cat.parent !== 'number' || cat.parent === 0) return 0;

      let tier = 0;
      let parent = cat;
      const categories = this.blogCategories;
      if (categories && typeof cat.parent === 'number') {
        let abort = Object.keys(categories).length;
        let count = 0;
        while (parent.parent !== 0 && count <= abort) {
          tier++;

          parent = categories[`${parent.parent}`] ?? 0;
          count++;
        }
      }

      return tier;
    },
    categoryClasses(cat) {
      const tier = this.getCategoryTier(cat);
      if (!cat || typeof cat.parent !== 'number') return ``;
      let classes = `category-${cat.slug} tier-${tier}`;

      if (tier > 0) {
        classes += ` child-category`;
      }

      return classes;
    },
    toggleCategoryDropdown() {
      if (this.expand) {
        this.closeDropdown();
      } else {
        this.openDropdown();
      }
      this.expand = !this.expand;
    },
    handleResize() {
      if (window.innerWidth >= sharedVariables.lg_min) {
        this.expand = false;
      }
    },
    closeDropdown() {
      const dropdown = this.$el.querySelector('.category-list');
      let icon = this.$el.querySelector('h3 .icon');
      gsap.to(dropdown, 0.3, {
        padding: '0 2rem 0 2rem',
        height: 0,
        ease: 'power3.inOut',
      });
      gsap.to(icon, 0.3, {
        rotation: 0,
        ease: 'power3.inOut',
      });
    },
    openDropdown() {
      const dropdown = this.$el.querySelector('.category-list');
      let icon = this.$el.querySelector('h3 .icon');
      gsap.to(dropdown, 0.3, {
        padding: '0',
        height: dropdown.scrollHeight + 20,
        ease: 'power3.inOut',
      });
      gsap.to(icon, 0.3, {
        rotation: 180,
        ease: 'power3.inOut',
      });
    },
  },
  mounted() {
    // on startup "select" all parent categories with children
    this.showChildren = this.processedCategories
      .filter(cat => cat.children.length > 0)
      .map(cat => cat.id);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  beforeMount() {
    window.addEventListener('resize', this.handleResize);
  },
};
