var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.page.type === 'archive-blog' || _vm.page.type === 'category-blog')?_c('div',{staticClass:"blog-sidebar archive-blog__sidebar",attrs:{"data-cy":"blog-sidebar"}},[_c('div',{staticClass:"box categories show-for-desktop"},[_c('h3',{staticClass:"small-title",domProps:{"textContent":_vm._s(_vm.$t('blog.categories'))}}),_vm._v(" "),_c('div',{staticClass:"category-list"},[_c('div',{staticClass:"category-item category-all tier-0"},[_c('nuxt-link',{attrs:{"to":_vm.$t('links.page.blog')}},[_vm._v(_vm._s(_vm.getAllLinkText))])],1),_vm._v(" "),_vm._l((_vm.processedCategories),function(cat,index){return _c('div',{key:index,staticClass:"category-item",class:_vm.categoryClasses(cat)},[_c('nuxt-link',{staticClass:"category-link",class:{
            active: _vm.isActiveCategory(cat.id),
          },attrs:{"to":cat.url}},[_vm._v(_vm._s(cat.title)+"\n        ")]),_vm._v(" "),(cat.parent === 0 && cat.children.length > 0)?_c('v-btn',{staticClass:"category-toggle",on:{"click":function($event){return _vm.toggleCategory(cat)}}},[_vm._v(_vm._s(_vm.showChildren.includes(cat.id) ? '-' : '+'))]):_vm._e(),_vm._v(" "),(cat.children.length > 0)?_c('div',{staticClass:"category-children",class:{ 'show-children': _vm.showChildren.includes(cat.id) }},_vm._l((cat.children),function(child,childIndex){return _c('div',{key:childIndex},[_c('nuxt-link',{staticClass:"category-link",class:{
                active: child.id === _vm.page.data.active_cat,
              },attrs:{"to":child.url}},[_vm._v(_vm._s(child.title)+"\n            ")])],1)}),0):_vm._e()],1)})],2)]),_vm._v(" "),_c('div',{staticClass:"box follow-us"},[_c('h3',{staticClass:"small-title",domProps:{"textContent":_vm._s(_vm.$t('blog.follow_us'))}}),_vm._v(" "),_c('div',{staticClass:"social-icons"},[_c('a',{staticClass:"share-link",attrs:{"href":_vm.$t('links.social.instagram'),"target":"_blank"}},[_c('instagram-svg')],1),_vm._v(" "),_c('a',{staticClass:"share-link",attrs:{"href":_vm.$t('links.social.facebook'),"target":"_blank"}},[_c('facebook-svg',{attrs:{"fill":'#fff'}})],1),_vm._v(" "),_c('a',{staticClass:"share-link",attrs:{"href":_vm.$t('links.social.youtube'),"target":"_blank"}},[_c('youtube-svg')],1)])])]):(_vm.page.type === 'single-blog')?_c('div',{staticClass:"archive-blog__sidebar single-blog__sidebar"},[_c('div',{staticClass:"box categories show-for-desktop"},[_c('h3',{staticClass:"small-title",domProps:{"textContent":_vm._s(_vm.$t('blog.categories'))}}),_vm._v(" "),_c('div',{staticClass:"category-list"},[_c('div',{staticClass:"category-item category-all tier-0"},[_c('nuxt-link',{attrs:{"to":_vm.$t('links.page.blog')}},[_vm._v(_vm._s(_vm.getAllLinkText))])],1),_vm._v(" "),_vm._l((_vm.processedCategories),function(cat,index){return _c('div',{key:index,staticClass:"category-item",class:_vm.categoryClasses(cat)},[_c('nuxt-link',{staticClass:"category-link",class:{
            active: _vm.isActiveCategory(cat.id),
          },attrs:{"to":cat.url}},[_vm._v(_vm._s(cat.title)+"\n        ")]),_vm._v(" "),(cat.parent === 0 && cat.children.length > 0)?_c('v-btn',{staticClass:"category-toggle",on:{"click":function($event){return _vm.toggleCategory(cat)}}},[_vm._v(_vm._s(_vm.showChildren.includes(cat.id) ? '-' : '+'))]):_vm._e(),_vm._v(" "),(cat.children.length > 0)?_c('div',{staticClass:"category-children",class:{ 'show-children': _vm.showChildren.includes(cat.id) }},_vm._l((cat.children),function(child,childIndex){return _c('div',{key:childIndex},[_c('nuxt-link',{staticClass:"category-link",class:{
                active: _vm.isActiveCategory(child.id),
              },attrs:{"to":child.url}},[_vm._v(_vm._s(child.title)+"\n            ")])],1)}),0):_vm._e()],1)})],2)]),_vm._v(" "),(_vm.relatedPosts)?_c('div',{staticClass:"box related-posts"},[_c('h3',{staticClass:"small-title",domProps:{"textContent":_vm._s(_vm.$t('blog.related_posts'))}}),_vm._v(" "),_c('div',{staticClass:"related-posts__list"},_vm._l((_vm.relatedPosts),function(post,index){return _c('nuxt-link',{key:index,attrs:{"to":post.link},domProps:{"textContent":_vm._s(post.title)}})}),1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"box follow-us"},[_c('h3',{staticClass:"small-title",domProps:{"textContent":_vm._s(_vm.$t('blog.follow_us'))}}),_vm._v(" "),_c('div',{staticClass:"social-icons"},[_c('a',{staticClass:"follow-link",attrs:{"href":_vm.$t('links.social.instagram'),"target":"_blank"}},[_c('instagram-svg')],1),_vm._v(" "),_c('a',{staticClass:"follow-link",attrs:{"href":_vm.$t('links.social.facebook'),"target":"_blank"}},[_c('facebook-svg',{attrs:{"fill":'#fff'}})],1),_vm._v(" "),_c('a',{staticClass:"follow-link",attrs:{"href":_vm.$t('links.social.youtube'),"target":"_blank"}},[_c('youtube-svg')],1)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }