//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  props: {
    page: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      blogCategories: 'blog/getBlogCategories',
    }),

    videoUrl() {
      if (this.page.featured_video) {
        if (this.page.featured_video.includes('www.youtube.com')) {
          return (
            this.page.featured_video + this.$t('blog.youtube_query_string')
          );
        }
        return this.page.featured_video;
      }
    },
  },
  methods: {
    getBlogCategory(blog) {
      if (blog.terms) {
        let termId = blog.terms[0];
        return this.blogCategories[termId];
      }
      return false;
    },
  },
};
